import React, { useEffect } from "react"
import Prism from "prismjs"
import { graphql, Link } from "gatsby"
import { SEO } from "../components"
import { DefaultLayout } from "../layouts"
import { MarkdownPostInterface } from "../typings/post.interface"

// Prism
import "prism-themes/themes/prism-shades-of-purple.min.css" // Theme

// Prism Language
import "prismjs/components/prism-javascript"
import "prismjs/components/prism-typescript"
import "prismjs/components/prism-python"
import "prismjs/components/prism-bash"
import "prismjs/components/prism-php-extras"

type PageDetailType = {
    frontmatter: MarkdownPostInterface
    html: string
}

type PageProps = {
    data: {
        page: PageDetailType
    }
}

const PageTemplate = (props: PageProps) => {
    useEffect(() => {
        Prism.highlightAll()
    }, [])

    const page = props.data.page
    const { frontmatter } = page
    const { title } = frontmatter
    return (
        <>
            <SEO title={title} />
            <DefaultLayout>
                <article
                    itemScope
                    itemType="http://schema.org/Article"
                    className="my-10"
                >
                    <header className="break-words mb-10 text-center">
                        <div className="w-full mx-auto font-inconsolata">
                            <h6 className="font-semibold text-4xl mb-2">
                                {title}
                            </h6>
                        </div>
                    </header>
                    <section
                        className="prose-lg prose-headings:font-inconsolata prose-headings:my-4 max-w-full break-words text-justify"
                        dangerouslySetInnerHTML={{ __html: page.html }}
                        itemProp="articleBody"
                    />
                </article>
            </DefaultLayout>
        </>
    )
}

export const PageQuery = graphql`
    query PageQuery($id: String!) {
        page: markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                slug
            }
        }
    }
`

export default PageTemplate
